exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calisma-alanlari-aile-hukuku-js": () => import("./../../../src/pages/calisma-alanlari/aile-hukuku.js" /* webpackChunkName: "component---src-pages-calisma-alanlari-aile-hukuku-js" */),
  "component---src-pages-calisma-alanlari-bosanma-avukati-js": () => import("./../../../src/pages/calisma-alanlari/bosanma-avukati.js" /* webpackChunkName: "component---src-pages-calisma-alanlari-bosanma-avukati-js" */),
  "component---src-pages-calisma-alanlari-ceza-avukati-js": () => import("./../../../src/pages/calisma-alanlari/ceza-avukati.js" /* webpackChunkName: "component---src-pages-calisma-alanlari-ceza-avukati-js" */),
  "component---src-pages-calisma-alanlari-ceza-hukuku-js": () => import("./../../../src/pages/calisma-alanlari/ceza-hukuku.js" /* webpackChunkName: "component---src-pages-calisma-alanlari-ceza-hukuku-js" */),
  "component---src-pages-calisma-alanlari-index-js": () => import("./../../../src/pages/calisma-alanlari/index.js" /* webpackChunkName: "component---src-pages-calisma-alanlari-index-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kurumsal-js": () => import("./../../../src/pages/kurumsal.js" /* webpackChunkName: "component---src-pages-kurumsal-js" */),
  "component---src-pages-makaleler-js": () => import("./../../../src/pages/makaleler.js" /* webpackChunkName: "component---src-pages-makaleler-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-sikca-sorulan-sorular-js": () => import("./../../../src/pages/sikca-sorulan-sorular.js" /* webpackChunkName: "component---src-pages-sikca-sorulan-sorular-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-tesekkur-ederiz-js": () => import("./../../../src/pages/tesekkur-ederiz.js" /* webpackChunkName: "component---src-pages-tesekkur-ederiz-js" */),
  "component---src-pages-yorumlar-js": () => import("./../../../src/pages/yorumlar.js" /* webpackChunkName: "component---src-pages-yorumlar-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

