import React, { useEffect, useState, Fragment } from "react"
import PropTypes from "prop-types"
import BodyClassName from "react-body-classname"
import { Link } from "gatsby"
import Logo from "../../images/logo.svg"

const activeStyle = {
  borderTop: "1px solid rgba(255, 255, 255, 0.5)",
  color: "#fff",
}

export default function Header({ location }) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
  }, [location])

  function renderLinks() {
    return (
      <Fragment>
        <ul className="level-1">
          <li>
            <Link className="level-1-a" to="/">
              Anasayfa
            </Link>
          </li>
          <li>
            <Link
              activeStyle={activeStyle}
              className="level-1-a"
              to="/makaleler"
            >
              Makaleler
            </Link>
          </li>
          <li>
            <Link
              activeStyle={activeStyle}
              className="level-1-a"
              to="/calisma-alanlari"
            >
              Çalışma Alanlarımız
            </Link>
          </li>
          <li>
            <Link
              activeStyle={activeStyle}
              className="level-1-a"
              to="/sikca-sorulan-sorular"
            >
              S.S.S.
            </Link>
          </li>
          <li>
            <Link
              activeStyle={activeStyle}
              className="level-1-a"
              to="/yorumlar"
            >
              Yorumlar
            </Link>
          </li>
          <li>
            <Link
              activeStyle={activeStyle}
              className="level-1-a"
              to="/kurumsal"
            >
              Kurumsal
            </Link>
          </li>
          <li>
            <Link
              activeStyle={activeStyle}
              className="level-1-a"
              to="/iletisim"
            >
              İletişim
            </Link>
          </li>
        </ul>
      </Fragment>
    )
  }
  return (
    <BodyClassName id="home" className={`${open ? "active drop" : ""}`}>
      <nav className="desktop">
        <div className="navigation-bar">
          <div className="logo">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="menu">{renderLinks()}</div>
          <a
            className="btn nobg btn-nav"
            href="tel:905536146416"
            rel="noopener noreferrer"
            target="_blank"
          >
            0553 614 64 16
          </a>
        </div>
        <div
          className={`nav-icon${open ? "" : ""}`}
          onClick={() => setOpen(!open)}
          onKeyDown={() => setOpen(!open)}
          aria-label="Toggle Menu"
          aria-hidden="false"
          tabIndex={0}
          role="button"
          styling="link"
        >
          <div className="hamburger-bar" />
        </div>
      </nav>
    </BodyClassName>
  )
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
}
