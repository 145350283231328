import React from "react"
import Google from "../../../static/assets/konum.svg"

const GoogleMap = () => (
  <div className="google-map">
    <a
      href="https://goo.gl/maps/P9rp6JwMJ2AX3x3z9"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img
        src={Google}
        alt="İzmir Avukat, İzmir Avukatları, İzmir Avukat Tavsiye, İzmir Boşanma Avukatı, İzmir Boşanma Avukatı Tavsiye, İzmir Boşanma Avukatları, İzmir Ağır Ceza Avukatı, İzmir Ağır Ceza Avukatları, İzmir Ağır Ceza Avukatı Tavsiye, İzmir İş Davası Avukatları, İzmir İş Davası Avukatı, İzmir İş Mahkemeleri Avukatları, İzmir Ceza Avukatı, İzmir Anlaşmalı Boşanma Avukatı, İzmir Nafaka Avukatı, İzmir Çekişmeli Boşanma Avukatı, İzmir İcra Avukatı, İzmir İş Kazası Avukatı, İzmir Miras Avukatı, İzmir İcra Avukatı, İzmir Tazminat Avukatı, İzmir Yabancılar Avukatı, İzmir Vatandaşlık Avukatı, İzmir Deport Avukatı"
        title="İzmir Avukat, İzmir Avukatları, İzmir Avukat Tavsiye, İzmir Boşanma Avukatı, İzmir Boşanma Avukatı Tavsiye, İzmir Boşanma Avukatları, İzmir Ağır Ceza Avukatı, İzmir Ağır Ceza Avukatları, İzmir Ağır Ceza Avukatı Tavsiye, İzmir İş Davası Avukatları, İzmir İş Davası Avukatı, İzmir İş Mahkemeleri Avukatları, İzmir Ceza Avukatı, İzmir Anlaşmalı Boşanma Avukatı, İzmir Nafaka Avukatı, İzmir Çekişmeli Boşanma Avukatı, İzmir İcra Avukatı, İzmir İş Kazası Avukatı, İzmir Miras Avukatı, İzmir İcra Avukatı, İzmir Tazminat Avukatı, İzmir Yabancılar Avukatı, İzmir Vatandaşlık Avukatı, İzmir Deport Avukatı"
      />
    </a>
  </div>
)

export default GoogleMap
