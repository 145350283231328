const Theme = {
  colors: {
    darkPurple: "#31235c",
    grey: "#EFEFEF",
    greyDark: "#E1E1E1",
    black: "#191A1E",
  },
  fonts: {
    work: `'Work Sans', sans-serif`,
    inter: `'inter', sans-serif`,
  },
  sizes: {
    mobile: "600px",
    tablet: "900px",
  },
}
export default Theme
