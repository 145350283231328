import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { GoogleMap, Whatsapp } from "../../components/Utils/"
import GlobalStyle from "../../styles/Global"
import Theme from "../../styles/Theme"
import styled, { ThemeProvider } from "styled-components"

import Header from "./Header"

import Footer from "./Footer"

const SiteRoot = styled.div`
  background: #fff;
`

const Layout = ({ children, location }) => (
  <SiteRoot>
    <Helmet>
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "İzmir",
            "addressRegion": "Bayraklı",
            "streetAddress": "Adalet"
          },
          "description": "İzmir Boşanma Avukatı, İzmir Deport Avukatı, İzmir Gayrimenkul Avukatı, İzmir İş Avukatı, İzmir En İyi Boşanma Avukatı, İzmir İcra Avukatı, İzmir Yabancılar Avukatı (İzmir Avukat) (Avukat İpek Kul - İzmir Boşanma Avukatı, İzmir Deport Avukatı, İzmir Gayrimenkul Avukatı, İzmir İş Avukatı, İzmir En İyi Boşanma Avukatı, İzmir İcra Avukatı, İzmir Yabancılar Avukatı (İzmir Avukat))",
          "name": "İzmir Avukat ve Hukuk Bürosu",
          "telephone": "05536146416"
        }
      `}
      </script>
    </Helmet>
    <GlobalStyle />

    <ThemeProvider theme={Theme}>
      <>
        <Header location={location} />
        {children}
        <Footer />
        <GoogleMap />
        <Whatsapp />
      </>
    </ThemeProvider>
  </SiteRoot>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
