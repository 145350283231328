import React from "react"
import Whatsapp from "../../../static/assets/whatsapp.svg"

const WhatsappIcon = () => (
  <a
    className="whats-app"
    href="tel:905536146416"
    rel="noopener noreferrer"
    target="_blank"
  >
    <div className="blob green">
      <img
        className="fa fa-whatsapp my-float"
        src={Whatsapp}
        alt="İzmir Avukat, İzmir Avukatları, İzmir Avukat Tavsiye, İzmir Boşanma Avukatı, İzmir Boşanma Avukatı Tavsiye, İzmir Boşanma Avukatları, İzmir Ağır Ceza Avukatı, İzmir Ağır Ceza Avukatları, İzmir Ağır Ceza Avukatı Tavsiye, İzmir İş Davası Avukatları, İzmir İş Davası Avukatı, İzmir İş Mahkemeleri Avukatları, İzmir Ceza Avukatı, İzmir Anlaşmalı Boşanma Avukatı, İzmir Nafaka Avukatı, İzmir Çekişmeli Boşanma Avukatı, İzmir İcra Avukatı, İzmir İş Kazası Avukatı, İzmir Miras Avukatı, İzmir İcra Avukatı, İzmir Tazminat Avukatı, İzmir Yabancılar Avukatı, İzmir Vatandaşlık Avukatı, İzmir Deport Avukatı"
        title="İzmir Avukat, İzmir Avukatları, İzmir Avukat Tavsiye, İzmir Boşanma Avukatı, İzmir Boşanma Avukatı Tavsiye, İzmir Boşanma Avukatları, İzmir Ağır Ceza Avukatı, İzmir Ağır Ceza Avukatları, İzmir Ağır Ceza Avukatı Tavsiye, İzmir İş Davası Avukatları, İzmir İş Davası Avukatı, İzmir İş Mahkemeleri Avukatları, İzmir Ceza Avukatı, İzmir Anlaşmalı Boşanma Avukatı, İzmir Nafaka Avukatı, İzmir Çekişmeli Boşanma Avukatı, İzmir İcra Avukatı, İzmir İş Kazası Avukatı, İzmir Miras Avukatı, İzmir İcra Avukatı, İzmir Tazminat Avukatı, İzmir Yabancılar Avukatı, İzmir Vatandaşlık Avukatı, İzmir Deport Avukatı"
      />
    </div>
  </a>
)

export default WhatsappIcon
